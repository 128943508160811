import { useSelector } from "react-redux";
import Table from "../table";
import "../style.scss";

export default function GoogleAds({ tableTitle = "" }) {
    const { google_ads } = useSelector(state => ({ google_ads: state.dataReducer.google_ads ?? [] }));

    return (
        <main>
            <h3>{tableTitle}</h3>
            <Table data={google_ads} />
        </main>
    );
}
