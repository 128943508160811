import { useSelector } from "react-redux";
import Table from "../table";
import "../style.scss";

export default function FacebookAds({tableTitle}) {
    const { facebook_ads } = useSelector(state => ({ facebook_ads: state.dataReducer.facebook_ads ?? [] }));

    return <main>
        <h3>{tableTitle}</h3>
        <Table data={facebook_ads}/>
    </main>
    
}