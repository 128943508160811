import { useSelector } from "react-redux";
import Table from "../table";
import "../style.scss";

export default function OrganicTraffic({tableTitle = ""}) {
    const { semrush } = useSelector(state => ({ semrush: state.dataReducer.semrush ?? [] }));

    return <main>
        <h3>{tableTitle}</h3>
        <Table data={semrush}/> 
    </main>
}