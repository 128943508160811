import { useSelector } from "react-redux";
import Table from "../table";
import "../style.scss";

export default function InstagramInfluencersAnalytics({ tableTitle = "" }) {
    const { instagram_influencers_counter } = useSelector(state => ({ instagram_influencers_counter: state.dataReducer.instagram_influencers_counter ?? [] }));

    return (
        <main>
            <h3>{tableTitle}</h3>
            <Table data={instagram_influencers_counter} />
        </main>
    );
}
