import { SET_DATA, SET_KEYWORD, SET_LOADING, CLEAR_TABLE_DATA } from './types';

export function setLoading() {
    return function(dispatch) {
        dispatch({ type: SET_LOADING })
    }
}

export function setTableData({ key, value }) {
   return function(dispatch) {
        dispatch({
            type: SET_DATA,
            payload: { key, value },
        })
   }
}

export function setKeyword(keyword) {
    return function(dispatch) {
        dispatch({ type: SET_KEYWORD, payload: keyword });
    }
}

export function clearTableData() {
    return function(dispatch) {
        dispatch({
            type: CLEAR_TABLE_DATA
        })
    }
}