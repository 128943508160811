import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { toast, ToastContainer } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';

import { setKeyword, setTableData as setTableDataInRedux, clearTableData } from "./utils/Redux/actions";

import Sidebar from "./components/Sidebar";
import FacebookAds from './components/FacebookAds';
import GoogleAds from './components/GoogleAds';
import OrganicTraffic from "./components/OrganicTraffic";
import GoogleMaps from"./components/GoogleMaps";
import Instagram from "./components/Instagram";
import YouTube from './components/Youtube';
import SimilarWeb from "./components/SimilarWeb";
import InstagramInfluencers from './components/InstagramInfluencers';
import InstagramInfluencersAnalytics from './components/InstagramInfluencersAnalytics';
import BrandPopularity from './components/BrandPopularity';
import Search from './components/Search';
import NotAvailable from './components/NotAvailable';
import Twitter from './components/Twitter';

import './style.scss';

const token = 'c3b22f4d8c659d6919f75e8850d77d3e90c095e5';

const tables = {
  'home': 'home',
  'organic-traffic': 'semrush',
  'facebook_ads': 'facebook_ads',
  'google-ads': 'google_ads',
  'similarweb': 'similarweb',
  'google_maps': 'google_maps',
  'instagram_socialblade': 'instagram_socialblade',
  'youtube_socialblade': 'youtube_socialblade',
  'instagram_influencers': 'instagram_influencers',
  'dataforseo': 'dataforseo',
  'instagram_influencers_counter': 'instagram_influencers_counter',
  'twitter_socialblade': 'twitter_socialblade'
};

export default function App() {
  const dispatch = useDispatch();

  const cachedTablesData = useSelector(state => state.dataReducer);

  const { keyword } = useSelector(state => ({
    keyword: state.dataReducer?.keyword
  }));

  const [downloadURL, setDownloadURL] = useState("");
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState("organic-traffic");
  const [status, setStatus] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [socket, setSocket] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isConnectedOnce, setIsConnectedOnce] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const setupSocketListeners = (newSocket) => {	  
    newSocket.on('connect', () => {
      setStatus('connected');
      setIsConnectedOnce(true);
    });

    newSocket.on('connect_error', (error) => {
      console.error('Connection Error:', error.message);
      setStatus('error');
      toast.error(`Connection Error: ${error.message}`);
    });

    newSocket.on('disconnect', () => {
      setStatus('disconnected');
    });

    newSocket.on('status', (data) => {
      setStatus(data.status);
      if (data.status === 'finished' && data.key) {
        setDownloadURL("https://test.espiaobrasileiro.com.br" + data.url ?? "");
        setActiveTab("organic-traffic");
        dispatch(setKeyword(data?.key));
        tables[activeTab] !== "home" && requestTableData(newSocket, data.key, tables[activeTab]);
        setData({ ...data, key: data.key });
      } else if (data.status === 'error') {
        console.error('Error:', data.error);
      }
    });

    newSocket.on('get_table_data', (data) => {
      if (data.data) {
        dispatch(setTableDataInRedux({ key: data.table, value: data.data }));
      } else {
        console.error('Table not available:', data.table);
      }
    });

    newSocket.on('timeout', () => {
      setStatus('timeout');
      setActiveTab('home');
      setSearchKeyword('');
	  newSocket.disconnect();
	  setSocket(null)

    });
  };

  useEffect(() => {
    if (socket) {
      tables[activeTab] !== "home" && requestTableData(socket, keyword, tables[activeTab]);
    }
  }, [activeTab, socket, keyword]);

  useEffect(() => {
    if (status === "searching" && socket) {
      const id = setInterval(() => {
        socket.emit('check_status', { data: 'status' });
      }, 5000);
      setIntervalId(id);

      return () => {
        clearInterval(id);
      };
    }
  }, [status, socket]);

  const requestTableData = (socket, key, table) => {	
	if (!cachedTablesData[table]?.length) {
    	socket.emit('get_table_data', { key, table });
	}
  };

  const emitSearchEvent = () => {
    if (searchKeyword.trim()) {
      dispatch(clearTableData());
      setStatus('searching');

      if (!socket) {
        const newSocket = io('wss://test.espiaobrasileiro.com.br/ws/v1/grand/', {
          auth: { token },
          transports: ["websocket", "polling"],
          reconnectionAttempts: 10
        });
        setSocket(newSocket);
        setupSocketListeners(newSocket);
        newSocket.emit('search', { keyword: searchKeyword });
      } else {
        socket.emit('search', { keyword: searchKeyword });
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      emitSearchEvent();
    }
  };

  const renderComponent = () => {
    switch (activeTab) {
      case "home":
        return (
          <Search
            status={status}
            keyword={keyword}
            emitSearchEvent={emitSearchEvent}
            searchKeyword={searchKeyword}
            handleKeyPress={handleKeyPress}
            setSearchKeyword={setSearchKeyword}
          />
        );
      case "organic-traffic":
        return <OrganicTraffic tableTitle={"Tráfego Orgânico pelo Google/SEO"} />;
      case "facebook_ads":
        return <FacebookAds tableTitle={"Anúncios Pagos Facebook/Instagram Ads"} />;
      case "google-ads":
        return <GoogleAds tableTitle={"Anúncios Pagos Google Ads"} />;
      case "google_maps":
        return <GoogleMaps tableTitle={"Google Maps"} />;
      case "similarweb":
        return <SimilarWeb tableTitle={"SimilarWeb"} />;
      case "instagram_influencers":
        return <InstagramInfluencers tableTitle={"Influenciadores do Instagram"} />;
      case "dataforseo":
        return <BrandPopularity tableTitle={"Popularidade da Marca"} />;
      case "instagram_influencers_counter":
        return <InstagramInfluencersAnalytics />;
      default:
        return (
          <Search
            status={status}
            keyword={keyword}
            emitSearchEvent={emitSearchEvent}
            searchKeyword={searchKeyword}
            handleKeyPress={handleKeyPress}
            setSearchKeyword={setSearchKeyword}
          />
        );
    }
  };

  if (screenWidth < 1024) {
    return <NotAvailable />
  }

  if (status === "pending") {
    return <div className="loading-spinner">
      <CircularProgress />
      <h3>A pesquisa está sendo realizada, aguarde 5 minutos..</h3>
    </div>;
  }

  return (
    <div className="app">
      <ToastContainer />
      {!data ? (
        <Search
          status={status}
          emitSearchEvent={emitSearchEvent}
          searchKeyword={searchKeyword}
          handleKeyPress={handleKeyPress}
          setSearchKeyword={setSearchKeyword}
        />
      ) : (
        <>
          <Sidebar
            downloadURL={downloadURL}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            searchKeyword={searchKeyword}
            setKeyword={setSearchKeyword}
            emitSearchEvent={emitSearchEvent}
          />
          <div className="main-content">
            {renderComponent()}
          </div>
        </>
      )}
    </div>
  );
}
