import { useDispatch } from 'react-redux';
import { setLoading } from '../../utils/Redux/actions';
import { SearchIcon } from '../../utils/SVGs';
import './style.scss';

export default function SearchBox({ keyword, setKeyword, onKeyPress, emitSearchEvent, showSearchIcon = true }) {
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
      setKeyword(e.target.value);
    };

    const handleSearch = () => {
        dispatch(setLoading());
        emitSearchEvent();
    }

    return(
        <div className='search-box'>
           <input
                type="text"
                value={keyword}
                onChange={handleInputChange}
                onKeyPress={onKeyPress}
                placeholder="pesquisar palavra"
            />
            {showSearchIcon && <div onClick={handleSearch}>{SearchIcon}</div>}

        </div>
    )
}