import underConstruction from "../../assets/images/undraw_under_construction_-46-pa.svg";
import './style.scss';

export default function NotAvailable ()  {
  return (
    <div className="not-available">
        <img src={underConstruction} alt= ""/>
        <p>Mobile access is not currently supported</p>
    </div>
  );
};
