import { useSelector } from "react-redux";
import Table from "../table";
import "../style.scss";

export default function InstagramInfluencers({ tableTitle = "" }) {
    const { instagram_influencers } = useSelector(state => ({ instagram_influencers: state.dataReducer.instagram_influencers ?? [] }));

    return (
        <main>
            <h3>{tableTitle}</h3>
            <Table data={instagram_influencers} />
        </main>
    );
}
