import React from 'react';
import logo from "../../assets/images/logo.png";
import { SearchIcon } from '../../utils/SVGs';

const sidebarLinks = [
    { key: "home", text: "pesquisar" },
    { key: "organic-traffic", text: "Trafego Organico" },
    { key: "facebook_ads", text: "Facebook Ads" },
    { key: "google-ads", text: "Google Ads" },
    {key: "dataforseo", text: "Popularidade Marca"},
    { key: "google_maps", text: "Google Maps" },
];

export default function Sidebar({ activeTab, setActiveTab, downloadURL }) {
    return (
        <div className='side-bar'>
            <img src={logo} alt="logo" />
            {sidebarLinks.map(link => (
                <div
                    key={link.key}
                    className="sidebar-link"
                    onClick={() => setActiveTab(link.key)}
                    style={{
                        backgroundColor: activeTab === link.key ? "#2AB1C6" : "#F2F2F2",
                        color: activeTab === link.key ? "#fff" : "#666666",
                        cursor: activeTab === link.key ? 'default' : 'pointer',
                    }}
                >
                    {link.text}
                    {link.key === 'home' && SearchIcon}

                </div>
            ))}

            <a href={downloadURL} download className='download-btn'>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"><path d="M9 11v6l2-2M9 17l-2-2" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M22 10v5c0 5-2 7-7 7H9c-5 0-7-2-7-7V9c0-5 2-7 7-7h5" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M22 10h-4c-3 0-4-1-4-4V2l8 8Z" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                Download Report
            </a>
        </div>
    );
}
