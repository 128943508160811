import { useSelector } from "react-redux";
import Table from "../table";
import "../style.scss";

export default function SimilarWeb({ tableTitle = "" }) {
    const { similarweb } = useSelector(state => ({ similarweb: state.dataReducer.similarweb ?? [] }));
    return (
        <main>
            <h3>{tableTitle}</h3>
            <Table data={similarweb} />
        </main>
    );
}