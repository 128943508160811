import { SET_DATA, SET_KEYWORD, SET_LOADING, CLEAR_TABLE_DATA } from "./types";

const initialState = {
  keyword: "",
  loading: false,
};


export default function dataReducer(state = initialState, action) {
    switch (action.type) {
      case SET_LOADING: {
        return { ...state, loading: true }
      }

      case SET_DATA: {
        const { key, value } = action.payload;

        return { ...state, [key]: value, loading: false };
      }

      case SET_KEYWORD: {
        return { ...state, keyword: action.payload }
      }

	  case CLEAR_TABLE_DATA:
		return {
		  ...initialState
		};

      default:
        return state;
    }
}