import { useSelector } from "react-redux";
import Table from "../table";
import "../style.scss";

export default function BrandPopularity({ tableTitle = "" }) {
    const { dataforseo } = useSelector(state => ({ dataforseo: state.dataReducer.dataforseo?? [] }));

    return (
        <main>
            <h3>{tableTitle}</h3>
            <Table data={dataforseo} />
        </main>
    );
}
