import { useEffect, useState } from "react";
import { notFound } from "../../utils/SVGs";
import externalLink from "../../assets/images/external-link.svg";
import "./style.scss";

const isValidUrl = (value) => {
    try {
        new URL(value);
        return true;
    } catch {
        return false;
    }
};

export default function Table({ data = [] }) {
    const [keys, setKeys] = useState([]);

    useEffect(() => {
        if (!keys.length && data.length) {
            setKeys(Object.keys(data[0]));
        }
    }, [data, keys.length]);

    if (!data.length) {
        return (
            <div className="not-found">
                {notFound}
                <span>No Data Found.</span>
            </div>
        );
    }

    return (
        <div className="table-wrapper">
            <div className="table-container">
                <div className="table-head table-row">
                    {keys.map(key => (
                        <span key={key} className="table-cell">{key}</span>
                    ))}
                </div>
                {data.map((row, rowIndex) => (
                    <div className="table-row" key={rowIndex}>
                        {keys.map(key => (
                            <span key={key} className="table-cell">
                                {key !== "Empresa" && isValidUrl(row[key]) ? (
                                    <a href={row[key]} target="_blank" rel="noopener noreferrer">
                                        <img src={externalLink} alt="external  link"/>
                                    </a>
                                ) : key === "Empresa" && isValidUrl(row[key]) ? (
                                    <a href={row[key]} target="_blank" rel="noopener noreferrer">
                                        {row[key]}
                                    </a>
                                ) : (
                                    row[key]
                                )}
                            </span>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}
