import { useEffect } from "react";
import { toast } from "react-toastify";

import SearchBox from "../SearchBox";
import logo from"../../assets/images/logo.png";
import "./style.scss";

export default function Search({status, emitSearchEvent, searchKeyword, setSearchKeyword, handleKeyPress }) {
    useEffect(() => {        
        status === "pending" && toast("We're working on scrapping the required data.");
        status === "error" && toast("Something went wrong, either to use another domain or try again.", { type: "error" });
    }, [status])

    return(
        <div className="initial-view">
            <img src={logo} alt="logo" />
            <SearchBox
                keyword={searchKeyword}
                setKeyword={setSearchKeyword}
                onKeyPress={handleKeyPress}
                showSearchIcon={false}
            />
            <button onClick={emitSearchEvent} disabled={status === "searching"}>
                {status === "searching" ? "Searching..." : "pesquisar"}
            </button>
        </div>
    )
}