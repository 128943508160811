import { useSelector } from "react-redux";
import Table from "../table";
import "../style.scss";

export default function GoogleMaps({ data = [], tableTitle = "" }) {
    const { google_maps} = useSelector(state => ({ google_maps: state.dataReducer.google_maps ?? [] }));

    return (
        <main>
            <h3>{tableTitle}</h3>
            <Table data={google_maps} />
        </main>
    );
}
